import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanitySocialPage {
        facebook
        instagram
      }
    }
  `);
  return (
    <footer className="flex flex-col p-14 text-lg text-gray-50 bg-green-900">
      <nav className="self-center mb-7">
        <Link className="mr-12" to="/">
          Home
        </Link>
        <Link className="mr-12" to="/projects">
          Projects
        </Link>
        <Link to="/contact">Contact Us</Link>
      </nav>
      <div className="self-center mb-7">
        <a href={data.sanitySocialPage.facebook}>
          <FontAwesomeIcon className="mr-12" icon={faFacebook} size="2x" />
        </a>
        <a href={data.sanitySocialPage.instagram}>
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
      </div>
      <div className="self-center">
        Made with{" "}
        <span role="img" aria-label="Rocket">
          🚀
        </span>{" "}
        by 16Pixels
      </div>
    </footer>
  );
};

export default Footer;
