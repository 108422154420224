import * as React from "react";
import Footer from "../footer";
import DropDownMenu from "../nav";

const Layout = ({ children }) => {
  return (
    <div className="bg-gray-900">
      <DropDownMenu
        items={[
          {
            label: "Home",
            link: "/",
          },
        ]}
      />
      <main className="bg-gray-900">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
